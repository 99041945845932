import React from "react"
import CatGif from "../images/cat.gif"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="wrap">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <h1>NOT FOUND</h1>
              <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
              <img src={CatGif} alt="Cat" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
